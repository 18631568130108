import jalaali from 'jalaali-js';
import API from './api'
import * as appConsts from "../store/consts";
import Swal from 'sweetalert2';

class Utils {
    formattedDate(currentDate) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }
    toPersianDate(date){
        const jalaaliDate = jalaali.toJalaali(date);

        // Custom format: 1399/01/01 (Year/Month/Day)
        const formattedPersianDate = `${jalaaliDate.jy}/${String(jalaaliDate.jm).padStart(2, '0')}/${String(jalaaliDate.jd).padStart(2, '0')}`;
      return formattedPersianDate;
    }
    toPersianDateTime(date){
        const jalaaliDate = jalaali.toJalaali(date);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        // Custom format: 1399/01/01 (Year/Month/Day)
        const formattedPersianDate = `${jalaaliDate.jy}/${String(jalaaliDate.jm).padStart(2, '0')}/${String(jalaaliDate.jd).padStart(2, '0')} ${hours}:${minutes}:${seconds}`;
      return formattedPersianDate;
    }
    getDateCurrentDifference(date2){
        let date1 = new Date();
       return this. getDateDifference(date1, date2)
    }
    getDateDifference(date1, date2) {
        const diffInMilliseconds = date2 - date1;
      
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const seconds = diffInSeconds % 60;
      
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const minutes = diffInMinutes % 60;
      
        const diffInHours = Math.floor(diffInMinutes / 60);
        const hours = diffInHours % 24;
      
        const diffInDays = Math.floor(diffInHours / 24);
      
        return {
          days: diffInDays,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      }
    addTimeToCurrentDate(amount, unit) {
        let date = new Date();
        console.log("BBB", date)
        return this.addTimeToDate(date, amount, unit)
    }
    addTimeToDate(date, amount, unit) {
        switch (unit) {
            case 'years':
                date.setFullYear(date.getFullYear() + amount);
                break;
            case 'months':
                date.setMonth(date.getMonth() + amount);
                break;
            case 'days':
                date.setDate(date.getDate() + amount);
                break;
            case 'hours':
                date.setHours(date.getHours() + amount);
                break;
            case 'minutes':
                date.setMinutes(date.getMinutes() + amount);
                break;
            case 'seconds':
                date.setSeconds(date.getSeconds() + amount);
                break;
            default:
                throw new Error('Invalid unit');
        }
        return date;
    }

    translateWordAPI(langTarget,word,funcSuccess){
        const myHeaders = new Headers();
        myHeaders.append("one-api-token", "427980:66e41aaf66540");
        myHeaders.append("Content-Type", "application/json");
        
        const raw = JSON.stringify({
         // "source": "fa",
          "target": langTarget,
          "text": word
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch("https://api.one-api.ir/translate/v1/google/", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            funcSuccess((JSON.parse(result)).result)
            // setItemUseState({
            //     ...itemUseState,
            //     translate: (JSON.parse(result)).result,
            // });
          
// console.log("TTTT",itemUseState)
        } )
        .catch((error) => console.error(error));

    }

    async getTranslates(lang){
    let translates=[];
    // await (new API()).sendGETWithoutToken(`/api/translate/${lang}`,(result)=> {
    //     console.log("RET",result)
    //     translates=result.data
    //     return translates;
    // })
    translates =await (new API()).sendGETWithoutToken(`/api/translate/${lang}`).then((result)=> {
        console.log("RET",result)
        translates=result.data
        return translates;
    })
    return translates;
    
      // return translates;
    }
//    async translateWord(lang,word){

//         //console.log("KJH",(translates))
//         // const results = translates.filter(item=>{return item.word==word})
//         // if(results.length==0)
//         //     return word;

//         // return results[0].translate;
//        console.log("CCC",lang);
//     word= await   (new API()).sendGETWithoutToken(`/api/translate/word/${lang}/${word}`,function(result){
//             console.log("AAAAA",result)
//             return result.trnsalte;

//         }).then(result=>{console.log("BBB",result.data.translate);return result.data.translate;})
//         return word;

//     }
 translateWord(translates,word){
 try {
    if(translates==undefined)
        return word;
    //console.log("KJH",(translates))
    const results = translates.filter(item=>{return item.word==word})
    if(results.length==0)
        return word;

    return results[0].translate;
 } catch (error) {
    return null;
 }
//    console.log("CCC",lang);
// word= await   (new API()).sendGETWithoutToken(`/api/translate/word/${lang}/${word}`,function(result){
//         console.log("AAAAA",result)
//         return result.trnsalte;

//     }).then(result=>{console.log("BBB",result.data.translate);return result.data.translate;})
//     return word;

}
emailValidation(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };




   loadCard = () => {
    let basket = localStorage.getItem("basket");
    if (!basket)
      basket = "[]";
    try {
      const arr_basket = JSON.parse(basket);
   return arr_basket;
     
    } catch (error) {
    return [];
    }


  }
   isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }


  showDeleteMessage(funcOk){
    Swal.fire({
        title: "مطمئن هستید",
        text: "آیا اطلاعات حذف شود؟!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "بلی حذف کنید!",
        cancelButtonText: "خیر"
      }).then((result) => {
        if (result.isConfirmed) { 
            funcOk();

         }
    });
}
showAlert(title,message,type="success"){
    Swal.fire({
        title: title,
        text: message,
        icon: type
      });
}
showQuestionMessage(message,funcOk){
    Swal.fire({
        title: "مطمئن هستید",
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "بلی حذف کنید!",
        cancelButtonText: "خیر"
      }).then((result) => {
        if (result.isConfirmed) { 
            funcOk();

         }
    });
}
 validatePassword(password) {
  // Define the criteria for password complexity
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

  // Check the length of the password
  if (password.length < minLength) {
      return false;
      //return { isValid: false, message: `Password must be at least ${minLength} characters long.` };
  }

  // Check for at least one uppercase letter
  if (!hasUpperCase) {
    return false;
    //return { isValid: false, message: "Password must contain at least one uppercase letter." };
  }

  // Check for at least one lowercase letter
  if (!hasLowerCase) {
    return false;  
    //return { isValid: false, message: "Password must contain at least one lowercase letter." };
  }

  // Check for at least one number
  if (!hasNumbers) {
    return false;
     // return { isValid: false, message: "Password must contain at least one number." };
  }

  // Check for at least one special character
  if (!hasSpecialChars) {
    return false;
      //return { isValid: false, message: "Password must contain at least one special character." };
  }

  // If all criteria are met, the password is valid
  //return { isValid: true, message: "Password is valid." };
  return true;
}

}
export default Utils;
//module.exports = Utils;