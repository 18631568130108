import React, { useCallback, useEffect, useState } from "react";
import NavItem from "./NavItem";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux'
import TopHomeWrapper from "./TopHomeWrapper";
import TopPageWrapper from "./TopPageWrapper";
import { addChangeView, addChangeLang, addChangeTranslateArr, addChangeBasket,addChangeLlogin } from '../../../../store/pathActions'
import API from "../../../../modules/api";
import Utils from "../../../../modules/utils";
import * as appConsts from '../../../../store/consts'
import './TopWrapper.css';
import Languages from "./Languages";
import RouteLink from "../../../Routing/RouteLink";

const TopWrapper = (props) => {
    const utl = new Utils();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemState, setItemState] = useState(window.location.pathname)
    const [translateState, setTranslateState] = useState([])
    const is_login = useSelector((state) => state.is_login)
    const path = useSelector((state) => state.path);

    const basket_count = useSelector((state) => state.basket_count)
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);

    useEffect(() => {
       // (alert(''))
        document.getElementById("preloader").classList.remove("d-none");
    },[path]);

    useEffect(() => {

        loadCard();

    }, [])
    const [homeState, setHomeState] = useState(
        {
            //logo_url: "/assets/images/Logo.png",
            logo_url: "",
            menu_name: "main",
            menu_name_edit: false,
            menu_items: [],

        });
    const [menuState, setMenuState] = useState({
        isHome: false,
        Menus: []
    })

    useEffect(() => {
        document.getElementById("preloader").classList.remove("d-none");
        loadData();


    }, [is_login,lang, tranlates, basket_count])

    useEffect(() => {
        document.getElementById("preloader").classList.remove("d-none");
        fillMenu();



    }, [homeState])

    useEffect(() => {
        document.getElementById("preloader").classList.remove("d-none");

        console.log("ABCD5", menuState)


    }, [menuState])

    useEffect(() => {
        document.getElementById("preloader").classList.remove("d-none");
        dispatch(addChangeView(itemState))


    }, [itemState])
    const clickMenuItem = (item) => {
        document.getElementById("preloader").classList.remove("d-none");
        menuState.isHome = item.isHome
        setItemState(item.path)
        //   navigate(item.path)

    }
    const loadCard = () => {
        const arr_basket = utl.loadCard();
        dispatch(addChangeBasket(arr_basket.length))
    }
    const clickLangItem = (item) => {
         document.getElementById("preloader").classList.remove("d-none");
        document.body.classList.remove("rtl")
        document.body.classList.remove("ltr")
        document.body.classList.add(item.dir)
        localStorage.setItem("lang", item.name)
        localStorage.setItem("dir", item.dir)
        dispatch(addChangeLang(item.name))

    }
    const fillMenu = () => {
        console.log("ABCD2", homeState)

        console.log('basket_count', basket_count)
        try {
            tranlates.then(result => {
                console.log("ABCD3", result)
                dispatch(addChangeTranslateArr(result))
                //  //console.log("tranlates4", result);
                // setTranslateState(result)
                let Menus = [];
                //appConsts.TopMenues.forEach(element => {
                homeState.menu_items.forEach(element => {
                    let children = [];
                    //console.log("LNK1", element.children);
                    element.children.forEach(child => {

                        children = [...children, { title: utl.translateWord(result, child.title), path: child.href, isSelect: false, isHome: false }];

                    })



                    Menus = [...Menus, { title: utl.translateWord(result, element.title), path: element.href, isSelect: false, isHome: (element.url == "/"), children: children }];
                });

                if (!is_login) {

                    Menus = [...Menus, { title: utl.translateWord(result, "ورود"), path: "/login", isSelect: false, isHome: false, isExternal: false, children: [] }];
                    Menus = [...Menus, { title: utl.translateWord(result, "ثبت نام"), path: "/register", isSelect: false, isHome: false, isExternal: false, children: [] }];
                } else {

                    //   Menus = [...Menus, { title: utl.translateWord(result, "پروفایل"), path: "/profile", isSelect: false, isHome: false,isExternal:false, children: [] }];
                    //   Menus = [...Menus, { title: utl.translateWord(result, "پنل کاربری"), path: "https://roles.tradinglocally.com/", isSelect: false, isHome: false,isExternal:true, children: [] }];
                    Menus = [...Menus, { title: utl.translateWord(result, "پنل کاربری"), path: "https://roles.tradinglocally.com/", isSelect: false, isHome: false, isExternal: true, children: [] }];


                }

                
                setMenuState({
                    ...menuState,

                    isHome: (window.location.pathname == "/"),
                    Menus: Menus

                })


            })
        } catch (error) {
          
        }

    }

    const loadMenus = (obj) => {
        let arrNames = [obj.menu_name];

        (new API()).sendPostWithoutToken(`/api/links-childs/names`, { names: arrNames }, (result) => {
            //console.log("MNU", result)
            if (result[obj.menu_name] != null) {
                obj[`menu_items`] = result[obj.menu_name];
            } else {
                obj[`menu_items`] = [];
            }

            setHomeState({ ...homeState, ...obj });

        });
    }



    const loadData = () => {

        let arrNames = ["home-header-logo-attach-id"];
        let arrImges = [];
        let arrTexts = [];
        let arrStatics = ['home-header-menu_name'];
        arrNames = [...arrNames, ...arrImges];
        arrNames = [...arrNames, ...arrStatics];


        let obj = { ...homeState };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                //console.log("TRE", result)
                if (result["home-header-logo-attach-id"] != null) {
                    obj[`logo_url`] = `${appConsts.api_url}/api/attachments/file/${result["home-header-logo-attach-id"]}`;
                }
                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-header-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-header-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }
                for (let index = 0; index < arrStatics.length; index++) {

                    const element = arrStatics[index];

                    const elementName = element.replace('home-header-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }


                
                loadMenus(obj);
                //   setHomeState({ ...homeState, ...obj });
            });

        } catch (error) {

            //console.log("ERR", error)
        }



    }




    // //console.log("ALI", window.location.pathname)
    menuState.isHome = (window.location.pathname == "/")


    const handlerExit=(event)=>{
        event.preventDefault();
        localStorage.removeItem("token")
        dispatch(addChangeLlogin(false))
        window.setTimeout(()=>{
            if(document.getElementById("preloader"))
            document.getElementById("preloader").classList.add("d-none");
           },3000);
    }


    return (


        <>

            {



                (menuState.isHome ? <TopHomeWrapper logo={homeState.logo_url}>
                    <ul className="navbar-nav ml-auto">
                        {

                            menuState.Menus.map((item, index) => {
                                return <NavItem item={item} click={() => clickMenuItem(item)} key={index} />
                            })

                        }
                       {is_login? <li className="nav-item"> <a href="#" onClick={handlerExit} className="nav-link" style={{fontSize:"22px"}}><i  style={{fontSize:"22px"}} className="icofont-exit"></i></a> </li>:null}
                        <Languages clicked={clickLangItem} />
                        <li className="nav-item"><RouteLink className="sob-basket" href="/shopping-card"><i className="icofont-food-basket"></i><div>{basket_count}</div></RouteLink> </li>

                    </ul>
                </TopHomeWrapper> : <TopPageWrapper logo={homeState.logo_url}>   <ul className="navbar-nav ml-auto">
                    {

                        menuState.Menus.map((item, index) => {
                            return <NavItem item={item} click={() => clickMenuItem(item)} key={index} />
                        })

                    }


                    <Languages clicked={clickLangItem} />

                    <li><RouteLink className="sob-basket" href="/shopping-card"><i className="icofont-food-basket"></i><div>{basket_count}</div></RouteLink> </li>
                </ul></TopPageWrapper>)

            }


        </>


    )
}


export default TopWrapper
