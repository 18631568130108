import React, { useEffect, useState } from 'react';

import { addChangeView,addChangeLlogin } from '../../../store/pathActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams,useSearchParams } from "react-router-dom";

import RouteLink from '../../../components/Routing/RouteLink';
import AuthContainer from '../../../components/site/Auth/AuthContainer/AuthContainer';
import API from "../../../modules/api";

import Utils from "../../../modules/utils";
import AuthTextBox from '../../../components/site/Auth/AuthTextBox/AuthTextBox';
import AuthAlert from '../../../components/site/Auth/AuthAlert/AuthAlert';
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import { useGoogleLogin } from '@react-oauth/google';
import Swal from 'sweetalert2'

const Register = () => {
  const utl = new Utils();
  const { id } = useParams();

  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);

  const navigate = useNavigate();

  const dispatch = useDispatch()
  const onRegisterclicked = (e) => {
    e.preventDefault();
    let temp="/login"
    if(id!=null){
      temp=`/login/${id}`
    }
    dispatch(addChangeView(temp))
    navigate(temp)
  }




  const [itemUseState, setItemUseState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    username: "",
    password: "",
    repassword: "",
    
  })

  React.useEffect(() => {

    const params = new URLSearchParams(window.location.search) // id=123
    let refer_id = params.get('refer_id') // 123 
    if(refer_id){
      setItemUseState({...itemUseState,refer_id:refer_id})
     }


}, [])





  const [myState, setMyState] = useState({
    first_name_label: "نام",
    last_name_label: "نام خانوادگی",
    email_label: "ایمیل",
    mobile_label: "موبایل",
    username_label: "نام کاربری",
    password_label: "کلمه عبور",
    repassword_label: "تکرار کلمه عبور",
    btn_sign_up_title: "ثبت نام",
    btn_sign_up_google_title: "ثبت نام با گوکل",
    or_label: "یا",
    or_option_label: "با ایمیل کاری خود ثبت نام کنید",
    have_account_label: "حساب کاربری دارم",
    login_label: "وارد شوید",
    is_error: false,
    err_message: "ggg",
    lst_err:[]
  })

  useEffect(() => {
    loadTransaltes();


  }, [])
  useEffect(() => {
    loadTransaltes();


  }, [tranlates])




  const loadTransaltes = () => {
    try {
      tranlates.then(result => {

        const obj = {
          first_name_label: utl.translateWord(result, myState.first_name_label),
          last_name_label: utl.translateWord(result, myState.last_name_label),
          email_label: utl.translateWord(result, myState.email_label),
          mobile_label: utl.translateWord(result, myState.mobile_label),
          username_label: utl.translateWord(result, myState.username_label),
          password_label: utl.translateWord(result, myState.password_label),
          repassword_label: utl.translateWord(result, myState.repassword_label),
          forget_password_label: utl.translateWord(result, myState.forget_password_label),
          btn_sign_up_title: utl.translateWord(result, myState.btn_sign_up_title),
          btn_sign_up_google_title: utl.translateWord(result, myState.btn_sign_up_google_title),
          or_label: utl.translateWord(result, myState.or_label),
          or_option_label: utl.translateWord(result, myState.or_option_label),
          have_account_label: utl.translateWord(result, myState.have_account_label),
          login_label: utl.translateWord(result, myState.login_label),
          is_error: false,
          err_message: "ggg",
          lst_err:[]
        }

        setMyState({ ...obj })


      })
    } catch {

    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;



    setItemUseState({
      ...itemUseState,
      [name]: value,
    });

  };

  const delay = (ms) => new Promise(res => setTimeout(res, ms));
  const checkValidation = async () => {
    setMyState({ ...myState, is_error: false });
    let is_error = false;
    let lst_err = [];
    if (itemUseState.first_name.trim().length == 0) { lst_err = [...lst_err, "نام"]; is_error = true; }

    if (itemUseState.last_name.trim().length == 0) { lst_err = [...lst_err, "نام خانوادگی"]; is_error = true; }


    if (itemUseState.username.trim().length == 0) { lst_err = [...lst_err, "نام کاربری"]; is_error = true; }



    if (itemUseState.password.trim().length == 0 || !new Utils().validatePassword(itemUseState.password)) { lst_err = [...lst_err, "کلمه عبور باید حداقل 8 کاراکتر و ترکیبی از اعداد ، حروف بزرگ ، کوچک و علائم باشد"]; is_error = true; }
    
    
    
    if (itemUseState.repassword.trim().length == 0) { lst_err = [...lst_err, "تکرار کلمه عبور"]; is_error = true; }
    
    
    if (itemUseState.email.trim().length == 0 || !utl.emailValidation(itemUseState.email)) { lst_err = [...lst_err, "ایمیل"]; is_error = true; }
    
    
    if (itemUseState.password != itemUseState.repassword) { lst_err = [...lst_err, "کلمه عبور با تکرارآن برابر نیست"]; is_error = true; }
    if (itemUseState.mobile.trim().length == 0) { lst_err = [...lst_err, "موبایل"]; is_error = true; }


setMyState({ ...myState, is_error: is_error});
    if (is_error) {
     
      try {
       const result= await tranlates;
           for (let index = 0; index < lst_err.length; index++) {
            const element = lst_err[index];
            lst_err[index]=utl.translateWord(result,element)
            
          }
          console.log('result', result);
          setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "اطلاعات ناقص است") ,lst_err:lst_err });
        // tranlates.then(result => {
        //   for (let index = 0; index < lst_err.length; index++) {
        //     const element = lst_err[index];
        //     lst_err[index]=utl.translateWord(result,element)
            
        //   }

        //   setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "اطلاعات ناقص است") ,lst_err:lst_err });
        // })
      } catch(zzz) {
        console.log('zzz', zzz)
        setMyState({ ...myState, is_error: true,  err_message : "اطلاعات ناقص است" ,lst_err:lst_err});
      }
    }


   return is_error;
  }

  const onRegisterUser =async (event) => {
    event.preventDefault();
   const is_error= await checkValidation();
    await delay(3000);

    
    if (is_error)
      return;

    console.log('data', itemUseState);
   
    (new API()).sendPostWithoutToken('/api/register/user',itemUseState,function (result) {
      Swal.fire({
        title: 'تائید',
        text: 'ثبت نام انجام شد',
        icon: 'success',
        confirmButtonText: 'باشه'
      })
      let temp="/login"
    if(id!=null){
      temp=`/login/${id}`
    }
      dispatch(addChangeView(temp))
      navigate(temp)
      console.log(result);
    },function (err) {
      if(err.response.status==520){
        try {
          tranlates.then(result => {
           
  
            setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, err.response.data.message) ,lst_err:[] });
          })
        } catch {
          setMyState({ ...myState, is_error: true,  err_message : err.response.data.message ,lst_err:[]});
        }
      }
      console.log(err);
      
    })

  }




  const google_login = useGoogleLogin({
    onSuccess: tokenResponse => handleSuccess(tokenResponse),
    onError:error => handleError(error)
  });


  const handleSuccess = (response) => {
    console.log(response);
    let data = {token:response.access_token};
    if(itemUseState.refer_id){
        data={...data,refer_id:itemUseState.refer_id}
    }
    (new API()).sendPostWithoutToken('/api/register/user/google',data,function (result) {
      localStorage.setItem("token", result.token)
      dispatch(addChangeLlogin(true))

      let temp="/"
      if(id!=null){
        temp=`/${id}`
      }

      dispatch(addChangeView(temp))
      navigate(temp)
      console.log(result);
    },function (err) {
      try {
        tranlates.then(result => {
         

          setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "ورود موفقیت آمیز نبود") ,lst_err:[] });
        })
      } catch {
        setMyState({ ...myState, is_error: true,  err_message : "ورود موفقیت آمیز نبود" ,lst_err:[]});
      }
    });
    // alert('')
    
    console.log('Login Success:', response);
    console.log('Login SuccessB:', response.access_token);

  };

  const handleError = (error) => {
    console.log('Login Failed:', error);
    try {
      tranlates.then(result => {
       

        setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "ورود موفقیت آمیز نبود") ,lst_err:[] });
      })
    } catch {
      setMyState({ ...myState, is_error: true,  err_message : "ورود موفقیت آمیز نبود" ,lst_err:[]});
    }
  };




  return (
    <AuthContainer title="خوش آمدید" dsc="ثبت نام کنید">
      {myState.is_error ? <AuthAlert message={myState.err_message} errors={myState.lst_err} /> : null}
      <form action="submit">
        <AuthTextBox name="first_name" changed={handleChange} placeholder={myState.first_name_label} />
        <AuthTextBox name="last_name" changed={handleChange} placeholder={myState.last_name_label} />
        <AuthTextBox name="username" changed={handleChange} placeholder={myState.username_label} />
        <AuthTextBox name="email" type='email' changed={handleChange} placeholder={myState.email_label} />
        <AuthTextBox name="password" type='password' changed={handleChange} placeholder={myState.password_label} />
        <AuthTextBox name="repassword" type='password' changed={handleChange} placeholder={myState.repassword_label} />
        <AuthTextBox name="mobile" changed={handleChange} placeholder={myState.mobile_label} />



        <div className="form-group">
          <button className="btn btn_main" type="submit" onClick={onRegisterUser}>{myState.btn_sign_up_title}  <i className="icofont-arrow-right"></i></button>
        </div>
      </form>
      <p className="or_block">
        <span>{myState.or_label}</span>
      </p>
      <div className="or_option">
        <p>{myState.or_option_label}</p>

        <a href="#" className="btn google_btn" onClick={google_login}><img src="/assets/images/google.png" alt="image" /> <span>{myState.btn_sign_up_google_title}</span> </a>
      
        <p>{myState.have_account_label}  <RouteLink href="/login">{myState.login_label}</RouteLink></p>
      </div>
    </AuthContainer>
  );
};

export default Register;