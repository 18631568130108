import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addChangeBasket } from '../../../store/pathActions'

import Utils from "../../../modules/utils";
import { Link } from 'react-router-dom';
import * as appConst from '../../../store/consts'
import Swal from 'sweetalert2'
function ProductSide({ product }) {
  const utl = new Utils();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);
  const [otherPackagesState, setOtherPackagesState] = useState([])
  const [myState, setMyState] = useState({
    list_title: "سایر پکیجهای محصول",
    count_title: "تعداد",
    count_postfix: "روز",
    add_to_basket_title: "افزودن به سبد خرید",
    go_to_basket_title: "مشاهده سبد خرید",
    basket_success_message: 'به سبد خرید اضافه شد',
    basket_success_title: 'تائید',
    basket_warning_message: 'در سبد خرید وجو دارد',
    basket_warning_title: 'اخطار',
    basket_ok_button: 'باشه',
  });

  useEffect(() => {
    try {
      tranlates.then(result => {
        const obj = {
          list_title: utl.translateWord(result, "سایر پکیجهای محصول"),
          count_title: utl.translateWord(result, "تعداد"),
          count_postfix: utl.translateWord(result, "روز"),
          add_to_basket_title: utl.translateWord(result, "افزودن به سبد خرید"),
          go_to_basket_title: utl.translateWord(result, "مشاهده سبد خرید"),
          basket_success_message: utl.translateWord(result, 'به سبد خرید اضافه شد'),
          basket_success_title: utl.translateWord(result, 'تائید'),
          basket_warning_message: utl.translateWord(result, 'در سبد خرید وجو دارد'),
          basket_warning_title: utl.translateWord(result, 'اخطار'),
          basket_ok_button: utl.translateWord(result, 'باشه'),

        };

        setMyState({ ...obj })
      })
    } catch {

    }


  }, [product, lang])
  useEffect(() => {
    loadOtherPackages();


  }, [product])

  const loadOtherPackages = async () => {
    const response = await fetch(`${appConst.api_url}/api/packages/product/${product.product.id}`)
    const result = await response.json();
    console.clear();
    console.log('result', result);
    try {
      tranlates.then(result2 => {
     const pks =    result.packages.map((item)=>{
          item.name = utl.translateWord(result2, item.name)
          return item;
        })
          console.log('pks', pks)
        setOtherPackagesState([...pks])
      })
    }
    catch {
      setOtherPackagesState(result.packages)
    }


  }



  const onAddToCard = (event) => {
    event.preventDefault();
    let basket = localStorage.getItem("basket");
    if (!basket)
      basket = "[]";
    try {
      let arr_basket = JSON.parse(basket);
      const is_has = arr_basket.filter((item) => item.id == product.id);
      if (is_has.length > 0) {

        Swal.fire({
          title: myState.basket_warning_title,
          text: myState.basket_warning_message,
          icon: 'warning',
          confirmButtonText: myState.basket_ok_button
        })
        return;
      }

      arr_basket = [...arr_basket, { ...product }]
      //  console.log("BSK1",arr_basket);
      //  console.log("BSK2",JSON.stringify(arr_basket));
      // console.log("BSK4",is_has);
      // console.log("BSK5",is_has.length);
      dispatch(addChangeBasket(arr_basket.length))
      localStorage.setItem("basket", JSON.stringify(arr_basket));
      Swal.fire({
        title: myState.basket_success_title,
        text: myState.basket_success_message,
        icon: 'success',
        confirmButtonText: myState.basket_ok_button
      })
    } catch (error) {
      // console.log("BSK1=3",error);


      // localStorage.removeItem("basket");
    }


  }

  return (
    <>


      <div className="col-12 col-lg-4">

        <div className="service_right_side">
          <div className="action_btn">
            <a href="javascript:;"  className="btn aos-init" data-aos="fade-up" data-aos-duration="1500">
              <span><i className="icofont-money"></i> </span>
              <p>{product.dollar_price.toLocaleString('en-US', { minimumFractionDigits: 2 })} $</p>
            </a>
            <a href="javascript:;"  className="btn aos-init" data-aos="fade-up" data-aos-duration="1500">
              <span><i className="icofont-money"></i> </span>
              <p>{product.rial_price.toLocaleString('en-US', { minimumFractionDigits: 0 })} ريال</p>
            </a>
          </div>
          <div className="side_contact_block aos-init" data-aos="fade-up" data-aos-duration="1500">
            <div className="icon"><i className="icofont-live-support"></i></div>
            <h3>{myState.count_title} {product.day_count} {myState.count_postfix}</h3>
            <a href="javascript:;" onClick={onAddToCard} className="btn btn_main">{myState.add_to_basket_title} <i className="icofont-shopping-cart"></i></a>
            <p><Link to="/shopping-card"><i className="icofont-food-basket"></i> {myState.go_to_basket_title}</Link></p>
          </div>



          <div className="service_list_panel aos-init mt-5" data-aos="fade-up" data-aos-duration="1500">
            <h3>{myState.list_title}</h3>
            <ul className="service_list">
              {otherPackagesState.map((item) => {
                return <>
                {
                 item.origin_type=="1" && <li><Link to={`/product/${item.id}`} className={item.id == product.id ? "active" : ""}>{item.name}</Link></li>
                }
                </>
              })}

            </ul>
          </div>



        </div>
      </div>

    </>

  )
}

export default ProductSide