import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from "react-router-dom";
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import './UniqFeatures.css';
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import Util from "../../../modules/utils";
function UniqFeatures() {
      const { id,title } = useParams();
  const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
      const dispatch = useDispatch();
      useEffect(() => {
          dispatch(addChangePageTitle(title))
  
      })
      const [homeFeatureState, setHomeFeatureState] = useState(null)
      useEffect(() => {
       loadData();

    },[lang])

    useEffect(() => {
        console.log('homeFeatureState', homeFeatureState);
        if(homeFeatureState !=null && homeFeatureState[`page_title_${id}_${lang}`]!=null)
        dispatch(addChangePageTitle(homeFeatureState[`page_title_${id}_${lang}`]))
 
     },[homeFeatureState])

      const loadData = () => {

        let arrNames = [];
        arrNames = [...arrNames, `home-feature-unique-page_video_${id}_${lang}-attach-id`];
        arrNames = [...arrNames, `home-feature-unique-page_video_cover_${id}_${lang}-attach-id`];
        arrNames = [...arrNames, `home-feature-unique-page_title_${id}_${lang}`];
        arrNames = [...arrNames, `home-feature-unique-page_content_${id}_${lang}`];
      
        let obj = {};
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
              
console.log('result', result)
                if(result[`home-feature-unique-page_video_${id}_${lang}-attach-id`]!=null){
                    const element=`home-feature-unique-page_video_${id}_${lang}-attach-id`;
                    const elementName = element.replace('home-feature-unique-', '').replace('-attach-id','_url');
                    obj[elementName] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                }
                if(result[`home-feature-unique-page_video_cover_${id}_${lang}-attach-id`]!=null){
                    const element=`home-feature-unique-page_video_cover_${id}_${lang}-attach-id`;
                    const elementName = element.replace('home-feature-unique-', '').replace('-attach-id','_url');
                    obj[elementName] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                }
                if(result[`home-feature-unique-page_title_${id}_${lang}`]!=null){
                    const element=`home-feature-unique-page_title_${id}_${lang}`;
                    const elementName = element.replace('home-feature-unique-', '');
                    obj[elementName] = result[element];
                }
                if(result[`home-feature-unique-page_content_${id}_${lang}`]!=null){
                    const element=`home-feature-unique-page_content_${id}_${lang}`;
                    const elementName = element.replace('home-feature-unique-', '');
                    obj[elementName] = result[element];
                }
                setHomeFeatureState({ ...homeFeatureState, ...obj });
            });

        } catch (error) {

        }



    }

    
  return (
    <>
    <div className='container-fluid p-5  text-center video'>
        <h1>{homeFeatureState!=null && homeFeatureState[`page_title_${id}_${lang}`]!=null && homeFeatureState[`page_title_${id}_${lang}`]}</h1>
      {homeFeatureState!=null && homeFeatureState[`page_video_cover_${id}_${lang}_url`] && homeFeatureState[`page_video_${id}_${lang}_url`] &&   <video className='img-fluid sob-video-single' src={homeFeatureState[`page_video_${id}_${lang}_url`]} poster={homeFeatureState[`page_video_cover_${id}_${lang}_url`]} controls/>}
    </div>
    <div className='container-fluid p-5 sob-uniqe-feature'>
        <p>{homeFeatureState!=null && homeFeatureState[`page_content_${id}_${lang}`]!=null && homeFeatureState[`page_content_${id}_${lang}`]}</p>
    </div>
    
    </>
  )
}

export default UniqFeatures