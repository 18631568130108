import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from "react-router-dom";
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import Util from "../../../modules/utils";
import './Landing.css'
function Landing() {
    const { prefix,id,title } = useParams();
    const lang = useSelector((state) => state.lang)
      const tranlates = useSelector((state) => state.tranlates);
        const dispatch = useDispatch();
        useEffect(() => {
            dispatch(addChangePageTitle(title))
    
        })
        const [homeState, sethomeState] = useState(null)
        useEffect(() => {
         loadData();
  
      },[lang])
  
      useEffect(() => {
          console.log('homeState', homeState);
          if(homeState !=null && homeState[`page_title${id!=null?'_' + id:''}_${lang}`]!=null)
          dispatch(addChangePageTitle(homeState[`page_title${id!=null?'_' + id:''}_${lang}`]))
   
       },[homeState])
  
        const loadData = () => {
  
          let arrNames = [];
          arrNames = [...arrNames, `${prefix}-page_video${id!=null?'_' + id:''}_${lang}-attach-id`];
          arrNames = [...arrNames, `${prefix}-page_video_cover${id!=null?'_' + id:''}_${lang}-attach-id`];
          arrNames = [...arrNames, `${prefix}-page_title${id!=null?'_' + id:''}_${lang}`];
          arrNames = [...arrNames, `${prefix}-page_content${id!=null?'_' + id:''}_${lang}`];
        
          let obj = {};
          try {
  
              (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                
  console.log('result', result)
                  if(result[`${prefix}-page_video${id!=null?'_' + id:''}_${lang}-attach-id`]!=null){
                      const element=`${prefix}-page_video${id!=null?'_' + id:''}_${lang}-attach-id`;
                      const elementName = element.replace(`${prefix}-`, '').replace('-attach-id','_url');
                      obj[elementName] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                  }
                  if(result[`${prefix}-page_video_cover${id!=null?'_' + id:''}_${lang}-attach-id`]!=null){
                      const element=`${prefix}-page_video_cover${id!=null?'_' + id:''}_${lang}-attach-id`;
                      const elementName = element.replace(`${prefix}-`, '').replace('-attach-id','_url');
                      obj[elementName] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
                  }
                  if(result[`${prefix}-page_title${id!=null?'_' + id:''}_${lang}`]!=null){
                      const element=`${prefix}-page_title${id!=null?'_' + id:''}_${lang}`;
                      const elementName = element.replace(`${prefix}-`, '');
                      obj[elementName] = result[element];
                  }
                  if(result[`${prefix}-page_content${id!=null?'_' + id:''}_${lang}`]!=null){
                      const element=`${prefix}-page_content${id!=null?'_' + id:''}_${lang}`;
                      const elementName = element.replace(`${prefix}-`, '');
                      obj[elementName] = result[element];
                  }
                  sethomeState({ ...homeState, ...obj });
              });
  
          } catch (error) {
  
          }
  
  
  
      }
  
      
    return (
      <>
      <div className='container-fluid p-5  text-center video'>
       
          <h1>{homeState!=null && homeState[`page_title${id!=null?'_' + id:''}_${lang}`]!=null && homeState[`page_title${id!=null?'_' + id:''}_${lang}`]}</h1>
        {homeState!=null && homeState[`page_video_cover${id!=null?'_' + id:''}_${lang}_url`] && homeState[`page_video${id!=null?'_' + id:''}_${lang}_url`] &&   <video className='img-fluid sob-video-single' src={homeState[`page_video${id!=null?'_' + id:''}_${lang}_url`]} poster={homeState[`page_video_cover${id!=null?'_' + id:''}_${lang}_url`]} controls/>}
      </div>
      <div className='container-fluid p-5 sob-uniqe-feature'>
          <p dangerouslySetInnerHTML={{ __html: homeState!=null && homeState[`page_content${id!=null?'_' + id:''}_${lang}`]!=null && homeState[`page_content${id!=null?'_' + id:''}_${lang}`] }}></p>
      </div>
      
      </>
    )
  }
export default Landing