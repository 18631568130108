import React from 'react'

function FooterCol1({mySate}) {
  return (
    <div className="col-lg-4 col-md-6 col-12">
    <div className="abt_side">
        <div className="logo"> <img src={mySate.logo_url} alt="image" /></div>
        <p>{mySate.dsc} </p>
        <div className="news_letter_block">
            {/* <form action="submit">
                <div className="form-group">
                    <input type="email" placeholder="Enter your email" className="form-control" />
                    <button className="btn"><i className="icofont-paper-plane"></i></button>
                </div>
            </form> */}
        </div>
    </div>
</div>
  )
}

export default FooterCol1