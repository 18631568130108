import React from 'react'
import Utils from "../../../modules/utils";
import {  useSelector } from 'react-redux'
function Contact2({data,lang}) {

  const tranlates = useSelector((state) => state.tranlates);
  const handleFormSubmit=async(event)=>{
    event.preventDefault();
   // console.log('event', event)
    const form = event.target;
  if (form.checkValidity()) {
    const utl =  new Utils();
   
    const result = await tranlates;
   
   utl.showAlert("!",utl.translateWord(result,"ارسال موفقیت آمیز بود"),"success");
  }
  }
  return (
    <section class="contact_form_section">
    <div class="container">
        <div class="contact_inner">
            <div class="contact_form">
              <div class="section_title">
                  <h2>{data[`contactus_form_title_${lang}`]}</h2>
                  <p>{data[`contactus_form_dsc_${lang}`]}</p>
              </div>
                <form action="submit" onSubmit={handleFormSubmit}>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder={data[`contactus_form_name_${lang}`]} class="form-control" required/>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="email" placeholder={data[`contactus_form_email_${lang}`]} class="form-control" required/>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" placeholder={data[`contactus_form_country_${lang}`]} class="form-control" required/>
                      </div>
                  </div>

                 
                </div>

          
                  <div class="form-group">
                      <textarea class="form-control" placeholder={data[`contactus_form_message_${lang}`]} required></textarea>
                  </div>

                 

                  <div class="form-group ">
                    <button class="btn btn_main" type="submit">{data[`contactus_form_button_${lang}`]} <i class="icofont-arrow-right"></i></button>
                </div>

                </form>

                <div class="form-graphic">
                  <img src="./assets/images/paperplane.png" alt="image"/>
                </div>
            </div>
            
            
        </div>
    </div>
  </section>
  )
}

export default Contact2