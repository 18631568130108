import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'



import Login from "../../container/site/Login/Login";
import Register from "../../container/site/Register/Register";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import './Layout-auth.css'
const LayoutSite = () => {
    useEffect(()=>{
       
    },[])
    return (


        <div className="page_wrapper">


            <div id="preloader" className=''>

                <div className="circle-border">
                    <div className="circle-core"></div>
                </div>
            </div>
            <Router>
                <Wrapper>

                    <div className="full_bg">

                        <div className="container">


                            <Routes>

                                <Route path="/login" element={<Login />} />
                                <Route path="/login/:id" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/register/:id" element={<Register />} />


                            </Routes>
                        </div>

                    </div>

                </Wrapper>
            </Router>








        </div>


    )
}

export default LayoutSite