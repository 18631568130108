import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import Util from "../../../../modules/utils";
import * as appConsts from "../../../../store/consts";
import './HomeTopBannerSection.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang,addChangeTranslateArr } from '../../../../store/pathActions'
import Languages from "./Languages";
import RouteLink from "../../../Routing/RouteLink";
import Utils from '../../../../modules/utils';

function HomeTopBannerSection() {
    const utl = new Util();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const txtInput = useRef();
    const [homebannerState, setHomebannerState] = useState(
        {
            banner1_url: "",
            banner2_url: "",
            banner3_url: "",
            banner4_url: "",
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: "",
            text6: "",
            text7: "",
            text8: "",
            // banner1_url: "/assets/banners/banner_01.png",
            // banner2_url: "/assets/banners/banner_02.png",
            // banner3_url: "/assets/banners/banner_03.png",
            // banner4_url: "/assets/banners/banner_04.png",
            // text1: "Powerful, easier and faster",
            // text2: "Grow your software",
            // text3: "faster & easier with Dashbord",
            // text4: "The World's simplest and fast platform that brings everything together",
            // text5: "Enter your email",
            // text6: "TRY FOR FREE",
            // text7: "Already using software?",
            // text8: "Sign in",
            btnsuccessMessage: "ارسال موفقیت آمیز بود",
            btnErrorMessage: "اطلاعات ارسالی اشتباه است",
        }
    );
    useEffect(() => {
        if(tranlates==undefined){
            dispatch(addChangeTranslateArr(lang))
        }
        loadData();

    }, [])
    useEffect(() => {
        if(tranlates==undefined){
            dispatch(addChangeTranslateArr(lang))
        }
        loadData();

    }, [lang,tranlates])




    const handleBtnClicked=(event)=>{
        event.preventDefault();
        if(txtInput.current.value.trim().length>0 && new Utils().emailValidation(txtInput.current.value.trim())){
            new Utils().showAlert("!",homebannerState.btnsuccessMessage,"success");
        }else{
            new Utils().showAlert("!",homebannerState.btnErrorMessage,"error");

        }
       // 
       console.log('txtInput.current.value', txtInput.current.value)
    }


    const loadData=()=>{


        try {
            tranlates.then(result2 => {
                
                let arrNames = [];
                for (let index = 1; index <= 4; index++) {
                    arrNames = [...arrNames, `home-banner${index}-attach-id`];
                }
        
        
                for (let index = 1; index <= 8; index++) {
                    arrNames = [...arrNames, `home-banner-text${index}`];
        
                }
                console.log("txt", arrNames);
                let obj = {...homebannerState,btnsuccessMessage:utl.translateWord(result2,homebannerState.btnsuccessMessage),btnErrorMessage:utl.translateWord(result2,homebannerState.btnErrorMessage)};

                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                    console.log('result', result)
                   
                    for (let index = 1; index <= 4; index++) {
                        arrNames = [...arrNames, `home-banner${index}-attach-id`];
                        if (result[`home-banner${index}-attach-id`] != null) {
                            const myVal = result[`home-banner${index}-attach-id`];
                           
                            obj[`banner${index}_url`] = `${appConsts.api_url}/api/attachments/file/${myVal}`;
        
        
                        }
                    }
        
        
        
        
                    for (let index = 1; index <= 8; index++) {
                        arrNames = [...arrNames, `home-banner-text${index}`];
                        if (result[`home-banner-text${index}`] != null) {
                            const myVal = result[`home-banner-text${index}`];
                            obj[`text${index}`] = utl.translateWord(result2,myVal);
        
        
                        }
                    }

                    setHomebannerState({ ...homebannerState, ...obj })
                   
                });


            })
        } catch (error) {

        }

       

    }


    
useEffect(()=>{
    console.log('homebannerState', homebannerState)
},[homebannerState])
  return (
   
    <section className="banner_section">
        <div className="container">
            <div className="banner_text">
                <div className="ban_inner_text aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                    <span>{homebannerState.text1}</span>
                    <h1>{homebannerState.text2} <br /> {homebannerState.text3}</h1>
                    <p>{homebannerState.text4}</p>
                </div>
                <form action="submit" data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
                    <div className="form-group">
                        <input ref={txtInput} type="text" className="form-control" placeholder={homebannerState.text5} />
                        <button onClick={handleBtnClicked} className="btn btn_main">{homebannerState.text6} <i className="icofont-arrow-right"></i></button>
                    </div>
                    <span>{homebannerState.text7} <RouteLink href="/login">{homebannerState.text8}</RouteLink></span>
                </form>
            </div>
            <div className="banner_images aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                <img src={homebannerState.banner1_url} alt="image" />
                <div className="sub_images">
                    <img className="moving_animation" src={homebannerState.banner2_url} alt="image" />
                    <img className="moving_animation" src={homebannerState.banner3_url} alt="image"  />
                    <img className="moving_animation" src={homebannerState.banner4_url} alt="image" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeTopBannerSection